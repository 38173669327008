module.exports={
  "platform": "talentbooking",
  "adminUrl": "https://live.echolocationtalent.com",
  "adminTitle": "Talent Booking CMS",
  "apiUrl": "https://api.echolocationtalent.com",
  "url": "https://live.echolocationtalent.com",
  "locale": "en-GB",
  "formats": {
    "date": {
      "isoDate": "YYYY-MM-DD",
      "short": "DD MMM YYYY",
      "long": "dddd DD MMMM YYYY"
    },
    "dateTime": {
      "short": "HH:mm DD MMM \"YY",
      "long": "h:mma on dddd D MMMM YYYY",
      "longTimeZone": "HH:mm A [on] dddd D MMMM YYYY"
    }
  },
  "adminLoginSuccessPath": "/",
  "darkroom": {
    "url": "https://darkroom.echolocationtalent.com",
    "salt": "diDu1aoQu8Woucat6coob6eshooweeco",
    "key": "ooyuacaishoorea4eita5xishairauSi"
  },
  "vatRate": "20",
  "twoFa": {
    "enabled": true,
    "mandatory": false
  }
}